import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';

import { SvgIcon } from '@components/icon';
import { Button } from '@components/shared/button';

type CancelButtonProps = {
  dark?: boolean;
  small?: boolean;
  handleClick?: () => void;
};

const styles = {
  CloseIconSize: {
    '@media (max-width: 599px)': {
      height: 14,
    },
  },
};

const CancelButton: React.FunctionComponent<CancelButtonProps> = ({
  handleClick,
  dark,
  small,
  ...rest
}) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="icon"
        css={{ width: 'auto', marginRight: 0, borderRadius: '0px' }}
        onClick={onClick}
        {...rest}
      >
        <VisuallyHidden>Cancel</VisuallyHidden>
        <SvgIcon
          css={styles.CloseIconSize}
          height={small ? '14' : '25'}
          alt="Close Button"
          src={dark ? 'close-dark.svg' : 'close-blue.svg'}
        />
      </Button>
    </>
  );
};

export default CancelButton;
