import styled from '@emotion/styled';

import { getRequiredFont } from '@utils/common';

export const FranchiseMainWrapper = styled.div`
  margin-top: 72px;
  padding: 20px 20px 90px;

  @media (max-width: 599px) {
    margin-top: 45px;
    padding-bottom: 0px;
  }
`;

export const FranchiseWrapper = styled.div`
  background: #fff;
  position: relative;

  :first-child > div {
    padding-top: 0;
  }
`;

export const FranchiseContent = styled.div`
  position: relative;
  background: #ecf9ff;
  width: 100vw;
  left: calc(-50vw + 50%);
  box-sizing: border-box;

  @media (max-width: 599px) {
    padding: 0px 20px;
  }
`;

export const FranchiseDetails = styled.div`
  max-width: 665px;
  margin: auto;
  padding: 45px 0px;

  @media (max-width: 599px) {
    padding: 45px 10px;
  }
`;

export const BorderedText = styled.div<{
  bg?: string;
  bR?: string;
  bL?: string;
}>`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: #172643;
    top: 50%;
    transform: translateY(-50%);
  }

  h1 {
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    width: fit-content;
    margin: auto;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    background-color: ${props => (props.bg ? props.bg : '#fff')};
  }
`;

export const HeaderText = styled.span<{
  color?: string;
  fFamily?: string;
  fWeight?: string;
  pt?: string;
  pl?: string;
  pb?: string;
  pr?: string;
  align?: string;
  itemWidth?: string;
}>`
  color: ${props => props.color || '#172643'};
  font-size: 20px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '600'};
  letter-spacing: 2.5px;
  line-height: 28px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'center'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;
`;

export const ListWrap = styled.ul<{
  gap?: string;
}>`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListLi = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  img {
    margin: 30px auto 0;
    height: 46px;
  }
`;

export const ListFlex = styled.li`
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

export const CircleCount = styled.span`
  max-width: 25px;
  width: 100%;
  height: 25px;
  border-radius: 50%;
  background: #172643;
  color: #b5e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 5px;
`;

export const StyledInput = styled.input`
  border-radius: 20px;
  border: 1px solid #cacaca;
  outline: none;
  box-shadow: none;
  padding: 10px 20px;
  margin-top: 10px;
`;

export const StyledSelect = styled.select`
  border-radius: 20px;
  border: 1px solid #cacaca;
  outline: none;
  box-shadow: none;
  padding: 10px 20px;
  margin-top: 10px;
  min-width: 167px;
  background-color: transparent;
  color: #737373;
  appearance: none;
  background: url(/images/right-arrow-thick.svg);
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 8px;
  max-height: 36px;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  border: 1px solid #cacaca;
  outline: none;
  box-shadow: none;
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%;
  resize: none;
  min-height: 130px;
  color: #737373;

  @media (max-width: 599px) {
    width: unset;
  }
`;

export const LabelInputWrap = styled.div<{
  disPlay?: string;
  mT?: string;
  mB?: string;
  mTm?: string;
}>`
  display: ${props => (props.disPlay ? props.disPlay : 'block')};
  margin-top: ${props => (props.mT ? props.mT : 'unset')};
  margin-bottom: ${props => (props.mB ? props.mB : 'unset')};
  @media (max-width: 599px) {
    display: grid;
    margin-top: ${props => (props.mTm ? props.mTm : 'mT')};
  }
`;
export const LabelWrap = styled.label`
  font-size: 14px;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  margin-left: 10px;
`;

export const TAndSList = styled.ul`
  padding-left: 16px;
  margin-top: 0;
  li {
    margin-bottom: 15px;

    span {
      font-family: proxima-nova, sans-serif;
      font-style: normal;
      font-weight: 100;
    }
  }
  @media (max-width: 599px) {
    margin-bottom: 0;
  }
`;

export const MarginTopNegative = styled.div`
  margin-top: -14px;
`;

export const FormTwoGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, calc(50% - 7.5px)) minmax(
      auto,
      calc(50% - 7.5px)
    );
  justify-content: center;
  align-items: flex-start;
  grid-gap: 15px;

  @media (max-width: 599px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

export const ThreeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 15px;

  @media (max-width: 599px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

export const FullGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, calc(50% - 7.5px)) minmax(
      auto,
      calc(50% - 7.5px)
    );
  justify-content: center;
  align-items: flex-start;
  grid-gap: 15px;

  @media (max-width: 599px) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const error = styled.div`
  color: #ec0e89;
  font-family: 'Proxima Nova';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 30px;
`;
