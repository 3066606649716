import { MembershipActionTypes } from '@models/memberships';
import { PricingModel } from '@models/memberships/membership';

import { makeActionCreator } from './index';

const setSelectedMembership = makeActionCreator<
  MembershipActionTypes.SET_SELECTED_MEMBERSHIP,
  PricingModel | null
>(MembershipActionTypes.SET_SELECTED_MEMBERSHIP);

export { setSelectedMembership };
