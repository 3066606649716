import { FooterItem } from '@models/common/footer';

export const preOpeningFooterItems: FooterItem[] = [
  {
    label: 'About Us',
    href: '/about',
    segmentTitle: 'Footer - About Us',
    mOrder: 1,
  },
  {
    label: 'Infrared & Chromo-What?',
    href: '/what-is-infrared',
    segmentTitle: 'Footer - Infrared & Chromotherapy',
    mOrder: 4,
  },
  {
    label: 'Terms of Use',
    href: '/terms',
    mOrder: 6,
  },
  {
    label: 'Contact Us',
    href: 'mailto:help@brightsideyoga.com',
    mOrder: 5,
  },
  {
    label: 'Join The Team',
    href: '/careers',
    segmentTitle: 'Footer - Careers',
    mOrder: 2,
  },
  {
    label: 'Classes',
    href: '/classes',
    mOrder: 3,
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
    mOrder: 7,
  },
];
