import { GuestWhenData, LocationService } from '@models/location';
import { PricingModel } from '@models/memberships/membership';
import { SessionModel } from '@models/session';
import { YogaSaunaActionTypes } from '@models/yoga-sauna';

import { makeActionCreator } from './index';

const setSelectedLocation = makeActionCreator<
  YogaSaunaActionTypes.SET_SELECTED_LOCATION,
  LocationService | null
>(YogaSaunaActionTypes.SET_SELECTED_LOCATION);

const resetSelectedDetails = makeActionCreator<
  YogaSaunaActionTypes.RESET_SELECTED_DETAILS,
  undefined
>(YogaSaunaActionTypes.RESET_SELECTED_DETAILS);

const setSelectedSession = makeActionCreator<
  YogaSaunaActionTypes.SET_SELECTED_SESSION,
  SessionModel | null
>(YogaSaunaActionTypes.SET_SELECTED_SESSION);

const setGuestEmail = makeActionCreator<
  YogaSaunaActionTypes.SET_GUEST_EMAIL,
  string | null
>(YogaSaunaActionTypes.SET_GUEST_EMAIL);

const setHasGuestEmailError = makeActionCreator<
  YogaSaunaActionTypes.SET_GUEST_EMAIL_ERROR,
  boolean
>(YogaSaunaActionTypes.SET_GUEST_EMAIL_ERROR);

const setIsGuestBooking = makeActionCreator<
  YogaSaunaActionTypes.SET_IS_GUEST_BOOKING,
  boolean
>(YogaSaunaActionTypes.SET_IS_GUEST_BOOKING);

const setWhenDataForGuestFlow = makeActionCreator<
  YogaSaunaActionTypes.SET_WHEN_DATA_FOR_GUEST,
  GuestWhenData | null
>(YogaSaunaActionTypes.SET_WHEN_DATA_FOR_GUEST);

const setIsWaitListBooking = makeActionCreator<
  YogaSaunaActionTypes.SET_IS_WAITLIST_BOOKING,
  boolean
>(YogaSaunaActionTypes.SET_IS_WAITLIST_BOOKING);

const setSelectedPaymentMethod = makeActionCreator<
  YogaSaunaActionTypes.SET_SELECTED_PAYMENT_METHOD,
  PricingModel | null
>(YogaSaunaActionTypes.SET_SELECTED_PAYMENT_METHOD);

const setSelectedDate = makeActionCreator<
  YogaSaunaActionTypes.SET_SELECTED_DATE,
  string | null
>(YogaSaunaActionTypes.SET_SELECTED_DATE);

export {
  setSelectedLocation,
  resetSelectedDetails,
  setSelectedSession,
  setGuestEmail,
  setHasGuestEmailError,
  setIsGuestBooking,
  setIsWaitListBooking,
  setSelectedPaymentMethod,
  setSelectedDate,
  setWhenDataForGuestFlow,
};
