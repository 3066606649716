import styled from '@emotion/styled';

import { getRequiredFont } from '@utils/common';

export type TextPropsModel = {
  color?: string;
  bgColor?: string;
  fSize?: string;
  fFamily?: string;
  fWeight?: string;
  lSpacing?: string;
  lHeight?: string;
  underline?: boolean;
  transform?: string;
  pt?: string;
  pl?: string;
  pb?: string;
  pr?: string;
  align?: string;
  itemWidth?: string;
  mb?: string;
  mfSize?: string;
  mfWeight?: string;
  mItemWidth?: string;
  mItemWidthImg?: string;
  mColor?: string;
  mUnderline?: boolean;
  mAlign?: string;
  mpt?: string;
  mpl?: string;
  mpb?: string;
  mpr?: string;
  mMargin?: string;
  hideLineMobile?: boolean;
  TextBorderColor?: string;
  TextBorderHeight?: string;
  hideLine?: boolean;
  curs?: string;
};

export type DivPropsModel = {
  fDirection?: string;
  jContent?: string;
  aItems?: string;
  flexFull?: string;
};

export type GridPropsDiv = {
  gridColumn?: string;
  gridGap?: string;
  mgridGap?: string;
  jContent?: string;
  aItems?: string;
  mjContent?: string;
  maItems?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  mpt?: string;
  mpb?: string;
  mpl?: string;
  mpr?: string;
  bg?: string;
  mgridColumn?: string;
  ipadGridColumn?: string;
  disp?: string;
};

export type PaddingPropsModel = {
  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
};

export const FirstConatiner = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justift-content: spacebetween;
`;

export const MainContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  // margin-top: 72px;
  margin-top: 130px;
  padding: 0px 20px 60px;
  box-sizing: border-box;
  // overflow: hidden;

  @media (max-width: 599px) {
    // margin-top: 50px;
  }
`;

// Flex Layout

export const FlexListCustom = styled.div<DivPropsModel>`
  display: flex;
  flex-direction: ${props =>
    props.fDirection === 'column' ? 'column' : 'row'};
  justify-content: ${props => (props.jContent ? props.jContent : 'flex-start')};
  align-items: ${props => (props.aItems ? props.aItems : 'center')};
  flex-wrap: wrap;
  flex: ${props => (props.flexFull ? '1' : 'auto')};
`;

export const GridCommon = styled.div<GridPropsDiv>`
  display: ${props => (props.disp ? props.disp : 'grid')};
  grid-template-columns: ${props =>
    props.gridColumn ? props.gridColumn : 'auto auto auto'};
  grid-gap: ${props => (props.gridGap ? props.gridGap : '15px')};
  padding-top: ${props => (props.pt ? props.pt : '0')};
  padding-left: ${props => (props.pl ? props.pl : '0')};
  padding-bottom: ${props => (props.pb ? props.pb : '0')};
  padding-right: ${props => (props.pr ? props.pr : '0')};
  background: ${props => (props.bg ? props.bg : 'unset')};
  justify-content: ${props => (props.jContent ? props.jContent : 'inherit')};
  align-items: ${props => (props.aItems ? props.aItems : 'initial')};

  @media (max-width: 900px) {
    grid-template-columns: ${props =>
      props.ipadGridColumn ? props.ipadGridColumn : props.gridColumn};
  }

  @media (max-width: 599px) {
    grid-template-columns: ${props =>
      props.mgridColumn ? props.mgridColumn : '1fr'};
    padding-top: ${props => (props.mpt ? props.mpt : '0')};
    padding-left: ${props => (props.mpl ? props.mpl : '0')};
    padding-bottom: ${props => (props.mpb ? props.mpb : '0')};
    padding-right: ${props => (props.mpr ? props.mpr : '0')};
    grid-gap: ${props => (props.mgridGap ? props.mgridGap : props.gridGap)};
    justify-content: ${props =>
      props.mjContent ? props.mjContent : 'inherit'};
    align-items: ${props => (props.maItems ? props.maItems : 'initial')};
  }
`;

export const GridTwoEqual = styled.div<{ gap?: boolean }>`
  display: grid;
  grid-gap: ${props => (props.gap ? '20px' : '20px')};
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  margin-bottom: 40px;

  @media (max-width: 599px) {
    grid-gap: ${props => (props.gap ? '0px' : '20px')};
    grid-template-columns: 1fr;
  }
`;

export const GridTwo = styled.div<{ gap?: string }>`
  display: grid;
  grid-gap: ${props => (props.gap ? props.gap : '50px')};
  grid-template-columns: 1fr 320px;
  align-items: flex-start;

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }
`;

// Fonts

export const CustomText = styled.span<TextPropsModel>`
  color: ${props => (props.color ? props.color : '#172643')};
  font-size: ${props => (props.fSize ? props.fSize : '18px')};
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => (props.fWeight ? props.fWeight : '600')};
  letter-spacing: ${props => (props.lSpacing ? props.lSpacing : '0.27px')};
  line-height: ${props => (props.lHeight ? props.lHeight : '20px')};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => (props.transform ? props.transform : 'none')};
  padding-top: ${props => (props.pt ? props.pt : '0')};
  padding-left: ${props => (props.pl ? props.pl : '0')};
  padding-bottom: ${props => (props.pb ? props.pb : '0')};
  padding-right: ${props => (props.pr ? props.pr : '0')};
  text-align: ${props => (props.align ? props.align : 'left')};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;
  cursor: ${props => (props.curs ? props.curs : 'pointer')};

  @media (max-width: 599px) {
    font-size: ${props => (props.mfSize ? props.mfSize : props.fSize)};
    font-family: ${props =>
      props.fFamily
        ? getRequiredFont(props.fFamily)
        : 'proxima-nova, sans-serif'};
    font-weight: ${props => (props.mfWeight ? props.mfWeight : '600')};
    width: ${props => (props.mItemWidth ? props.mItemWidth : 'auto')};
    color: ${props => (props.mColor ? props.mColor : '#172643')};
    text-decoration: ${props => (props.mUnderline ? 'underline' : 'none')};
    text-align: ${props => (props.mAlign ? props.mAlign : 'left')};
    padding-top: ${props => (props.mpt ? props.mpt : '0')};
    padding-left: ${props => (props.mpl ? props.mpl : '0')};
    padding-bottom: ${props => (props.mpb ? props.mpb : '0')};
    padding-right: ${props => (props.mpr ? props.mpr : '0')};
  }
`;

export const ExtraSmallText = styled.span<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 12px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '300'};
  letter-spacing: 0.24px;
  letter-spacing: 0.24px;
  line-height: 15px;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;
`;

export const SmallText = styled.span<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 16px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '300'};
  letter-spacing: ${props => props.lSpacing || '0.24px'};
  line-height: 20px;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;
`;

export const BaseText = styled.span<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 18px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '600'};
  letter-spacing: ${props => props.lSpacing || '0.17'};
  line-height: 22px;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    // font-size: 16px;
  }
`;

export const MediumText = styled.span<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 20px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '600'};
  letter-spacing: 0.27px;
  line-height: 27px;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    font-size: 18px;
  }
`;

export const LargeText = styled.span<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 24px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '600'};
  letter-spacing: 2.9px;
  line-height: 36px;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    font-size: 20px;
    padding-top: ${props => props.mpt || '0'};
    padding-left: ${props => props.mpl || '0'};
    padding-bottom: ${props => props.mpb || '0'};
    padding-right: ${props => props.mpr || '0'};
  }
`;

export const XLargeText = styled.h1<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 34.5px;
  letter-spacing: 4.31px;
  line-height: 38px;
  font-family: ${props =>
    props.fFamily
      ? getRequiredFont(props.fFamily)
      : 'proxima-nova, sans-serif'};
  font-weight: ${props => props.fWeight || '600'};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  margin: 0;
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'left'};
  width: ${props => (props.itemWidth ? props.itemWidth : 'auto')};
  display: inline-block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.31px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const GradientWithStroke = styled.h1<TextPropsModel>`
  font-size: 42px;
  letter-spacing: 2.63px;
  line-height: 48.43px;
  font-family: arya-single, sans-serif;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-left: -4px;
  background: -webkit-linear-gradient(
    136.67deg,
    #b5e8ff 0%,
    #f4c9ff 52.41%,
    #ffc9c9 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin: 0;
  margin: 12px 0 40px;
  ::before {
    width: 100%;
    content: attr(data-tooltip);
    -webkit-text-stroke: 1.5px #172643;
    position: absolute;
    color: transparent;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
  }
`;
export const WelcomeText = styled.div`
  color: #737373;
  font-family: 'Proxima Nova';
  font-size: 18px;
  letter-spacing: 0.07px;
  line-height: 27px;
  text-align: center;
  padding: 0 36px;
  margin: 10px 0 23px 0;
  @media (max-width: 599px) {
    margin-top: 50px;
  }
`;

export const Header = styled.h1<TextPropsModel>`
  color: ${props => props.color || '#172643'};
  font-size: 34.5px;
  letter-spacing: 4.31px;
  line-height: 38px;
  font-family: ${props =>
    props.fFamily ? getRequiredFont(props.fFamily) : 'arya-single, sans-serif'};
  font-weight: ${props => props.fWeight || 'normal'};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'uppercase'};
  margin: 0;
  padding-top: ${props => props.pt || '40px'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '50px'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'center'};
  width: ${props => (props.itemWidth ? props.itemWidth : '100%')};
  display: inline-block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 2px;
    padding-top: ${props => props.mpt || '25px'};
    padding-bottom: ${props => props.mpb || '25px'};
  }
`;

export const SubHeader = styled.h1<TextPropsModel>`
  color: ${props => props.color || '#737373'};
  font-size: 20px;
  font-family: ${props =>
    props.fFamily ? getRequiredFont(props.fFamily) : 'proxima-nova'};
  font-weight: ${props => props.fWeight || '300'};
  letter-spacing: 0.27px;
  line-height: 27px;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-transform: ${props => props.transform || 'none'};
  margin: 0px auto;
  padding-top: ${props => props.pt || '0'};
  padding-left: ${props => props.pl || '0'};
  padding-bottom: ${props => props.pb || '0'};
  padding-right: ${props => props.pr || '0'};
  text-align: ${props => props.align || 'center'};
  width: ${props => (props.itemWidth ? props.itemWidth : '100%')};
  display: inline-block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 24px;
    width: ${props => (props.mItemWidth ? props.mItemWidth : 'auto')};
  }
`;

export const StyledLinkButton = styled.a<{ small?: boolean; mobMl?: string }>`
  display: block;
  padding: ${props => (props.small ? '10.5px 25px' : '12px 16px')};
  border: 0px solid;
  border-radius: 30px;
  cursor: pointer;
  font-size: ${props => (props.small ? '15px' : '18px')};
  font-weight: 700;
  letter-spacing: ${props => (props.small ? '1.1px' : '1.69px')};
  line-height: ${props => (props.small ? '18px' : '22px')};
  text-align: center;
  max-width: 336px;
  color: #172643;
  width: ${props => (props.small ? 'auto' : '100%')};
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  box-sizing: border-box;
  background: linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  text-decoration: none;

  @media (max-width: 1024px) {
    margin-left: ${props => (props.mobMl ? props.mobMl : 'auto')};
  }

  @media (max-width: 599px) {
    margin-left: ${props => (props.mobMl ? props.mobMl : 'auto')};
  }
`;

export const HeaderWithLines = styled.div<TextPropsModel>`
  position: relative;
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;

  :before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: ${props => props.TextBorderHeight || '1px'};
    background-color: ${props => props.TextBorderColor || ' #E1E1E1'};
  }

  div,
  span,
  * {
    text-align: center;
    position: relative;
    margin: auto;
    padding: 0px 10px;
    max-width: 85%;
    background-color: ${props => props.bgColor || '#ffffff'};
  }

  @media (max-width: 599px) {
    max-width: ${props => (props.mItemWidth ? props.mItemWidth : '100%')};

    :before {
      opacity: ${props => (props.hideLine ? 0 : 1)};
    }
    div,
    span,
    * {
      max-width: 100%;
    }

    img {
      max-width: ${props =>
        props.mItemWidthImg ? props.mItemWidthImg : '100%'};
    }
  }
`;

export const ParaText = styled.span<TextPropsModel>`
  color: ${props => (props.color ? props.color : '#172643')};
  font-size: ${props => (props.fSize ? props.fSize : '18px')};
  font-family: ${props =>
    props.fFamily ? getRequiredFont(props.fFamily) : 'proxima-nova'};
  font-weight: ${props => props.fWeight || '300'};
  letter-spacing: 0.27px;
  line-height: ${props => (props.lHeight ? props.lHeight : '28px')};
  text-decoration: none;
  text-transform: ${props => (props.transform ? props.transform : 'none')};
  padding-top: ${props => (props.pt ? props.pt : '0')};
  padding-left: ${props => (props.pl ? props.pl : '0')};
  padding-bottom: ${props => (props.pb ? props.pb : '0')};
  padding-right: ${props => (props.pr ? props.pr : '0')};
  text-align: ${props => (props.align ? props.align : 'left')};
  display: block;
  box-sizing: border-box;

  @media (max-width: 599px) {
    font-size: ${props => (props.mfSize ? props.mfSize : '18px')};
    max-width: ${props => (props.mItemWidth ? props.mItemWidth : 'auto')};
    text-align: ${props => (props.mAlign ? props.mAlign : 'left')};
    margin: ${props => (props.mMargin ? props.mMargin : 'auto')};
  }
`;

export const FloatRightOverInput = styled.div({
  position: 'absolute',
  top: 26,
  right: 1,
  display: 'flex',
  alignItems: 'center',

  img: {
    marginRight: 15,
    pointerEvents: 'none',
    cursor: 'none',
  },
});

export const HideMobile = styled.div`
  display: block;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const ShowMobile = styled.div`
  display: none;

  @media (max-width: 700px) {
    display: block;
    position: relative;
  }
`;

export const ShowMobileCustom = styled.div`
  display: none;

  @media screen and (min-width: 200px) and (max-width: 480px) {
    display: block;
    position: relative;
  }
`;
export const ShowMobileCustom2 = styled.div`
  display: none;

  @media screen and (min-width: 481px) and (max-width: 600px) {
    display: block;
    position: relative;
  }
`;

export const HideIpad = styled.div`
  display: block;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const HideIpadCustom = styled.div`
  display: none;

  @media screen and (min-width: 601px) and (max-width: 800px) {
    display: block;
  }
`;

export const HideIpadCustom2 = styled.div`
  display: none;

  @media screen and (min-width: 801px) and (max-width: 1024px) {
    display: block;
  }
`;

export const HideSmallScreens = styled.div`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ShowSmallScreens = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const HideDesktop = styled.div`
  display: none;

  @media (max-width: 599px) {
    display: block;
    position: relative;
  }
`;

export const Pricingintro = styled.div`
  background: #ecf9ff;
  text-align: center;
  padding: 26px;
  max-width: 544px;
  margin: 5px auto 10px;
  // @media (max-width: 599px) {
  //   margin: 0px auto 15px;
  // }
`;
