import React from 'react';

import { SvgIcon } from '@components/icon';
import { instagramUrl } from '@utils/constants';

interface InstragramProps {
  fireClickEvent: (args: string) => void;
  gradient?: boolean;
}

const Instagram: React.FunctionComponent<InstragramProps> = ({
  fireClickEvent,
  gradient,
}) => (
  <a
    href={instagramUrl}
    target="_blank"
    rel="noreferrer"
    onClick={() => fireClickEvent('Footer - Instagram')}
  >
    {gradient ? (
      <SvgIcon width={32} src="instagram-gradiant.svg" alt="Instagram" />
    ) : (
      <SvgIcon width={32} src="instagram.svg" alt="Instagram" />
    )}
  </a>
);

export default Instagram;
