import { SaunaActionTypes } from '@models/sauna';
import { FilterTypes } from '@models/session';

import { makeActionCreator } from './index';

const setSaunaFilters = makeActionCreator<
  SaunaActionTypes.SET_SAUNA_FILTERS,
  FilterTypes
>(SaunaActionTypes.SET_SAUNA_FILTERS);

const clearSaunaFilters = makeActionCreator<
  SaunaActionTypes.CLEAR_SAUNA_FILTERS,
  undefined
>(SaunaActionTypes.CLEAR_SAUNA_FILTERS);

export { setSaunaFilters, clearSaunaFilters };
