import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { reset } from '@actions/gift';
import { setSelectedMembership } from '@actions/memberships';
import { clearSaunaFilters } from '@actions/sauna';
import { clearYogaFilters } from '@actions/yoga';
import {
  resetSelectedDetails,
  setSelectedLocation,
  setSelectedSession,
} from '@actions/yoga-sauna';
import { FullGrid } from '@components/franchise/franchise.styled';
import { SvgIcon } from '@components/icon';
import {
  Modal,
  ModalContents,
  ModalDismissButton,
  ModalOpenButton,
} from '@components/modal';
import { Button } from '@components/shared/button';
import {
  CustomText,
  FlexListCustom,
  HideIpad,
  HideMobile,
} from '@components/shared/common';
import { ModalActionButtons } from '@components/shared/modal';
import { useAuth } from '@context/auth';
import { useGiftDispatch } from '@context/gift';
import { useMembershipsDispatch } from '@context/membership';
import { useSaunaDispatch } from '@context/sauna';
import { useYogaDispatch } from '@context/yoga';
import { useYogaSaunaDispatch } from '@context/yoga-sauna';
import { HeaderProps } from '@models/common/header';
import { LocationService } from '@models/location';
import useSessionSegment from '@services/analytics/use-session-analytics';
import { useLocations } from '@services/yoga-sauna';
import useYogaSaunaLocation from '@services/yoga-sauna/use-yoga-sauna-location';
import { parseUrlSlug } from '@utils/common';
import { SERVICE } from '@utils/constants';

import {
  FixedBanner,
  GradientButton,
  GradientButtonWhite,
  GradientWrapper,
  GradientWrapperWhite,
  GroupedButton,
  GroupedButtonWhite,
  StyledHeader,
  StyledHeaderMain,
  StyledHeaderPreOpen,
  StyledHeaderWrap,
} from './header.styled';

const MainMenu = dynamic(() => import('@components/menu'));
const BackButton = dynamic(() => import('@components/shared/back-button'));
const CancelButton = dynamic(() => import('@components/shared/cancel-button'));

const HeaderNavigation: React.FunctionComponent<HeaderProps> = ({
  showBackOption,
  showCloseOption,
  showMenuOption,
  showBookButton = false,
  homePageStyles = false,
  handleBack: navBack,
  handleCancel,
  showCloseConfirmModal = true,
  ispreOpening = false,
  showJoinTheTeamButton = false,
  isBannerRequired = false,
}) => {
  const [showBookingOptions, setShowBookingOptions] = React.useState(false);
  const [showHideMenu, setShowHideMenu] = React.useState(false);
  const router = useRouter();
  const { user } = useAuth();
  const { data: locations = [] } = useLocations();
  const yogaDispatch = useYogaDispatch();
  const SaunaDispatch = useSaunaDispatch();
  const yogaSaunaDispatch = useYogaSaunaDispatch();
  const membershipDispatch = useMembershipsDispatch();
  const { getSpecificLocation } = useYogaSaunaLocation();
  const giftDispatch = useGiftDispatch();
  const { trackSessionEvents } = useSessionSegment();
  const [windowWidth, setWindowWidth] = React.useState(0);
  useEffect(() => {
    if (window) {
      setWindowWidth(window.innerWidth);
    }
  }, []);

  React.useEffect(() => {
    if (locations.length && router) {
      locations[0].service.forEach(loc => {
        router.prefetch(
          '/book-a-class/locations/[name]/available-sessions',
          `/book-a-class/locations/${parseUrlSlug(
            loc.name,
          )}/available-sessions`,
        );
        router.prefetch(
          '/book-a-sauna/locations/[name]/available-sessions',
          `/book-a-sauna/locations/${parseUrlSlug(
            loc.name,
          )}/available-sessions`,
        );
      });
    }
  }, [router, locations]);

  const handleBack = () => {
    if (navBack) {
      navBack();
      return;
    }
    router.back();
  };

  const handleClose = () => {
    if (handleCancel) {
      handleCancel();
      return;
    }
    const redirectRoute = user ? '/my-sessions' : '/';
    router.replace(redirectRoute).then(() => {
      yogaDispatch(clearYogaFilters(undefined));
      SaunaDispatch(clearSaunaFilters(undefined));
      yogaSaunaDispatch(setSelectedLocation(null));
      yogaSaunaDispatch(resetSelectedDetails(undefined));
      membershipDispatch(setSelectedMembership(null));
      giftDispatch(reset(undefined));
    });
  };

  const handleBookYogaOrSauna = (type: string) => {
    const location = getSpecificLocation(type);
    yogaSaunaDispatch(setSelectedLocation(location as LocationService));
    const pathPrefix = type === SERVICE.yoga ? 'book-a-class' : 'book-a-sauna';
    const pathname = `/${pathPrefix}/locations/[name]/available-sessions`;
    const asPath = `/${pathPrefix}/locations/${parseUrlSlug(
      location?.name as string,
    )}/available-sessions`;
    yogaSaunaDispatch(setSelectedSession(null));
    router.push(pathname, asPath).then(() => {
      membershipDispatch(setSelectedMembership(null));
    });
  };

  const onJoinTheTeamBtnClick = () => {
    router.push('/careers');
  };

  const displayBookingOptions = () => {
    setShowBookingOptions(true);
    trackSessionEvents('Header - Book', [], {}, 'track');
  };

  const renderBackNav = () =>
    showBackOption && <BackButton handleClick={handleBack} />;

  const handleClick = () => {
    trackSessionEvents('Cancel Purchase', ['Category', 'Referring Page Title']);
  };

  const abandonContents = () => (
    <ModalContents title="Are you sure?">
      <CustomText
        fSize="20px"
        fWeight="300"
        mfWeight="300"
        lHeight="23px"
        lSpacing="0.3px"
        color="#737373"
        mColor="#737373"
        mAlign="center"
        align="center"
        pl="40px"
        pr="40px"
        pt="30px"
        pb="100px"
        itemWidth="100%"
        mItemWidth="100%"
        mpt="30px"
        mpl="40px"
        mpr="40px"
        mpb="100px"
      >
        If you exit now, your selections won&apos;t be saved.
      </CustomText>
      <ModalActionButtons>
        <ModalDismissButton>
          <Button type="button" variant="outline">
            Go Back
          </Button>
        </ModalDismissButton>
        <ModalDismissButton>
          <Button type="button" onClick={handleClose}>
            Yes
          </Button>
        </ModalDismissButton>
      </ModalActionButtons>
    </ModalContents>
  );

  const renderRightNav = () => (
    <>
      {showCloseOption &&
        (showCloseConfirmModal ? (
          <Modal>
            <ModalOpenButton>
              <div
                tabIndex={0}
                role="link"
                onKeyPress={handleClick}
                onClick={handleClick}
              >
                <CancelButton />
              </div>
            </ModalOpenButton>
            {abandonContents()}
          </Modal>
        ) : (
          <CancelButton handleClick={handleClose} />
        ))}
      {showMenuOption && (
        <MainMenu
          setShowHideMenu={setShowHideMenu}
          showHideMenu={showHideMenu}
          homePageStyles={homePageStyles}
        />
      )}
    </>
  );

  const renderLogo = () => (
    <SvgIcon
      className="brand-logo"
      width="200"
      css={{ color: 'white' }}
      src={
        homePageStyles && windowWidth >= 600 && !showHideMenu
          ? 'Logo-white.svg'
          : 'logo.svg'
      }
      alt="BrightSide Logo"
      onClick={() => {
        router.push('/');
      }}
    />
  );

  if (ispreOpening && showJoinTheTeamButton) {
    return (
      <StyledHeaderWrap homePageStyles={!(!homePageStyles || showHideMenu)}>
        <StyledHeaderPreOpen>
          <FlexListCustom jContent="flex-start" aItems="center">
            <HideIpad>
              <FullGrid>
                {/* <GradientButton className="GradientButton" type="button">
                  <span>Book</span>
                </GradientButton> */}
                <GradientButton
                  // btnSize="small"
                  css={{ height: 37 }}
                  type="submit"
                  onClick={() => onJoinTheTeamBtnClick()}
                >
                  JOIN THE TEAM
                </GradientButton>
              </FullGrid>
            </HideIpad>
          </FlexListCustom>
          <div
            css={{ display: 'flex', textAlign: 'center', alignItems: 'center' }}
          >
            {renderLogo()}
          </div>
          <div />
        </StyledHeaderPreOpen>
      </StyledHeaderWrap>
    );
  }

  const renderGradiantButton = () => (
    <>
      {homePageStyles ? (
        <>
          <GradientButtonWhite
            type="button"
            onClick={displayBookingOptions}
            homePageStyles={homePageStyles}
          >
            <span>Book</span>
            <SvgIcon
              width="7"
              alt="right arrow Button"
              src={
                homePageStyles
                  ? 'arrow-right-gradient.svg'
                  : 'arrow-right-gradient.svg'
              }
            />
          </GradientButtonWhite>
        </>
      ) : (
        <>
          <GradientButton
            type="button"
            onClick={displayBookingOptions}
            homePageStyles={homePageStyles}
          >
            <span>Book</span>
            <SvgIcon
              width="7"
              alt="right arrow Button"
              src={
                homePageStyles
                  ? 'arrow-right-gradient.svg'
                  : 'arrow-right-gradient.svg'
              }
            />
          </GradientButton>
        </>
      )}
    </>
  );

  const renderGradiantWrapper = () => (
    <>
      {homePageStyles ? (
        <>
          <GradientWrapperWhite>
            <GroupedButtonWhite
              className="GradientGroupedButton"
              type="button"
              onClick={() => handleBookYogaOrSauna(SERVICE.yoga)}
            >
              Book Yoga
            </GroupedButtonWhite>
            <GroupedButtonWhite
              pl="20px"
              type="button"
              css={{ borderLeft: '1px solid #fff' }}
              className="GradientGroupedButton"
              onClick={() => handleBookYogaOrSauna(SERVICE.sauna)}
            >
              Book Sauna
            </GroupedButtonWhite>
          </GradientWrapperWhite>
        </>
      ) : (
        <>
          <GradientWrapper>
            <GroupedButton
              className="GradientGroupedButton"
              type="button"
              onClick={() => handleBookYogaOrSauna(SERVICE.yoga)}
            >
              Book Yoga
            </GroupedButton>
            <GroupedButton
              pl="20px"
              type="button"
              css={{ borderLeft: '1px solid #F4C9FF' }}
              className="GradientGroupedButton"
              onClick={() => handleBookYogaOrSauna(SERVICE.sauna)}
            >
              Book Sauna
            </GroupedButton>
          </GradientWrapper>
        </>
      )}
    </>
  );

  return (
    <>
      <StyledHeaderWrap>
        {!showHideMenu && isBannerRequired && (
          <FixedBanner>
            <a href="/pricing">
              Intro Offer — $49 for your 1st month of an unlimited yoga
              membership, use code <span>NEWMBR</span>{' '}
            </a>
          </FixedBanner>
        )}
        <StyledHeaderMain homePageStyles={!(!homePageStyles || showHideMenu)}>
          <StyledHeader>
            <FlexListCustom jContent="flex-start" aItems="center">
              {renderBackNav()}

              {!ispreOpening &&
                !showHideMenu &&
                showBookButton &&
                !showBookingOptions &&
                renderGradiantButton()}
              {!ispreOpening &&
                !showHideMenu &&
                !showJoinTheTeamButton &&
                showBookingOptions &&
                renderGradiantWrapper()}
              {showJoinTheTeamButton && (
                <HideMobile>
                  <FullGrid>
                    <Button
                      btnSize="small"
                      css={{ maxWidth: 300, width: '100%', height: 37 }}
                      type="submit"
                      onClick={() => onJoinTheTeamBtnClick()}
                    >
                      JOIN THE TEAM
                    </Button>
                  </FullGrid>
                </HideMobile>
              )}
            </FlexListCustom>
            <div
              css={{
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              {renderLogo()}
            </div>
            {!ispreOpening && (
              <FlexListCustom jContent="flex-end" aItems="center">
                {renderRightNav()}
              </FlexListCustom>
            )}
          </StyledHeader>
        </StyledHeaderMain>
      </StyledHeaderWrap>
    </>
  );
};

HeaderNavigation.defaultProps = {
  showBackOption: false,
  showCloseOption: false,
  showMenuOption: false,
  showBookButton: false,
  showJoinTheTeamButton: false,
};

export default HeaderNavigation;
