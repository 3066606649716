import React from 'react';

import CancelButton from '@components/shared/cancel-button';
import { BaseText } from '@components/shared/common';
import { ModalHeaderWrapper } from '@components/shared/modal';
import { ModalHeaderProps } from '@models/common/modal';

const ModalHeader: React.FunctionComponent<ModalHeaderProps> = ({
  title,
  whiteBg,
  showCloseButton,
  closeModal,
}) => (
  // className={whiteBg ? 'model-header white-bg' : 'model-header'}
  // css={{
  //   padding: '20px 40px',
  //   display: 'grid',
  //   gridTemplateColumns: '14px 1fr 14px',
  //   gridGap: '10px',
  //   position: 'sticky',
  //   top: '0px',
  //   zIndex: 1,
  //   alignItems: 'center',
  // }}
  <ModalHeaderWrapper whiteBg={whiteBg}>
    <span />
    <BaseText
      align="center"
      transform="uppercase"
      data-testid="modal-header"
      fFamily="aryaSingle"
      fWeight="500"
      css={{ letterSpacing: 2.25 }}
    >
      {title}
    </BaseText>
    <span css={{ float: 'right' }}>
      {showCloseButton && <CancelButton small dark handleClick={closeModal} />}
    </span>
  </ModalHeaderWrapper>
);

export default ModalHeader;
