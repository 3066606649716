import styled from '@emotion/styled';

import { HeaderProps } from '@models/common/header';
import { base, primary, transparent } from '@styles/colors';

export const StyledHeaderWrap = styled.div<HeaderProps>`
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // background: ${props => (props.homePageStyles ? transparent : primary)};
  // background: ${props => (props.homePageStyles ? primary : primary)};
  box-sizing: border-box;
  z-index: 10;
  max-height: 72px;
  min-height: 72px;
  display: flex;
  align-items: center;
  transition: background linear 200ms;
  flex-direction: column;
  width: 100%;
  @media (max-width: 599px) {
    background: #172643;
    min-height: 45px;
    max-height: unset;
    flex-direction: column-reverse;
  }
`;

export const StyledHeaderMain = styled.div<HeaderProps>`
  background: ${props => (props.homePageStyles ? transparent : primary)};
  // background: ${props => (props.homePageStyles ? primary : primary)};
  display: grid;

  width: 100%;
  max-height: 72px;
  min-height: 72px;
  @media (max-width: 599px) {
    background: #172643;
    min-height: 45px;
  }
`;

export const StyledHeader = styled.div<HeaderProps>`
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  grid-gap: 15px;
  align-items: center;
  max-width: 1100px;
  margin: auto;
  padding-left: 22px;
  padding-right: 22px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 599px) {
    grid-template-columns: 1fr auto 1fr;
  }
`;

export const StyledHeaderPreOpen = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
  grid-gap: 15px;
  align-items: center;
  max-width: 1180px;
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 599px) {
    grid-template-columns: 1fr auto 1fr;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const GradientButtonWhite = styled.button<{ homePageStyles?: boolean }>`
  cursor: pointer;
  width: auto;
  padding: 8px 15px 6px 20px;
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  border-radius: 30px;
  border: 0px;
  // box-shadow: ${props =>
    props.homePageStyles
      ? 'none'
      : `-1px 0 0 1px #ffc9c9, -1px -1px 0 1px #ffc9c9,
        -1px -1px 0 1px #ffc9c9, 0 -1px 0 1px #f4c9ff, 0px 1px 0 1px #b5e8ff,
        1px -1px 0 1px #b5e8ff, 1px 1px 0 1px #b5e8ff, 1px 0 0 1px #b5e8ff`}; //
  box-shadow: ${props =>
    props.homePageStyles
      ? `-1px 0 0 1px #fff, -1px -1px 0 1px #fff,
      -1px -1px 0 1px #fff, 0 -1px 0 1px #fff, 0px 1px 0 1px #fff,
      1px -1px 0 1px #fff, 1px 1px 0 1px #fff, 1px 0 0 1px #fff`
      : `-1px 0 0 1px #fff, -1px -1px 0 1px #ffc9c9,
              -1px -1px 0 1px #fff, 0 -1px 0 1px #fff, 0px 1px 0 1px #fff,
              1px -1px 0 1px #fff, 1px 1px 0 1px #fff, 1px 0 0 1px #fff`};

  // background: ${props =>
    props.homePageStyles
      ? `linear-gradient(136.67deg, #FFC9C9 0%, #F4C9FF 52.41%, #B5E8FF 100%)`
      : `linear-gradient(
      136.67deg,
      #ffc9c9 0%,
      #f4c9ff 52.41%,
      #b5e8ff 100%
    )`}; //

  background: none;

  background-clip: ${props => (props.homePageStyles ? 'none' : 'text')};
  -webkit-background-clip: ${props => (props.homePageStyles ? 'none' : 'text')};
  // -webkit-text-fill-color: ${props =>
    props.homePageStyles ? base : '#ffc9c9'};//

  -webkit-text-fill-color: ${props => (props.homePageStyles ? '#fff' : '#fff')};
  display: flex;
  align-items: center;

  span {
    // margin-right: 8px;
    font-family: inherit;
    font-weight: inherit;
  }

  @media (max-width: 599px) {
    display: none;
  }

  img {
    display: none;
  }
`;

export const GradientButton = styled.button<{ homePageStyles?: boolean }>`
  cursor: pointer;
  width: auto;
  padding: 8px 15px 6px 20px;
  font-size: 16px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  border-radius: 30px;
  border: 0px;
  // box-shadow: ${props =>
    props.homePageStyles
      ? 'none'
      : `-1px 0 0 1px #ffc9c9, -1px -1px 0 1px #ffc9c9,
        -1px -1px 0 1px #ffc9c9, 0 -1px 0 1px #f4c9ff, 0px 1px 0 1px #b5e8ff,
        1px -1px 0 1px #b5e8ff, 1px 1px 0 1px #b5e8ff, 1px 0 0 1px #b5e8ff`}; //
  box-shadow: ${props =>
    props.homePageStyles
      ? `-1px 0 0 1px #ffc9c9, -1px -1px 0 1px #ffc9c9,
      -1px -1px 0 1px #ffc9c9, 0 -1px 0 1px #f4c9ff, 0px 1px 0 1px #b5e8ff,
      1px -1px 0 1px #b5e8ff, 1px 1px 0 1px #b5e8ff, 1px 0 0 1px #b5e8ff`
      : `-1px 0 0 1px #ffc9c9, -1px -1px 0 1px #ffc9c9,
              -1px -1px 0 1px #ffc9c9, 0 -1px 0 1px #f4c9ff, 0px 1px 0 1px #b5e8ff,
              1px -1px 0 1px #b5e8ff, 1px 1px 0 1px #b5e8ff, 1px 0 0 1px #b5e8ff`};

  // background: ${props =>
    props.homePageStyles
      ? `linear-gradient(136.67deg, #FFC9C9 0%, #F4C9FF 52.41%, #B5E8FF 100%)`
      : `linear-gradient(
      136.67deg,
      #ffc9c9 0%,
      #f4c9ff 52.41%,
      #b5e8ff 100%
    )`}; //

  background: none;

  background-clip: ${props => (props.homePageStyles ? 'none' : 'text')};
  -webkit-background-clip: ${props => (props.homePageStyles ? 'none' : 'text')};
  // -webkit-text-fill-color: ${props =>
    props.homePageStyles ? base : '#ffc9c9'};//

  -webkit-text-fill-color: ${props =>
    props.homePageStyles ? '#ffc9c9' : '#ffc9c9'};
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
    font-family: inherit;
    font-weight: inherit;
  }

  @media (max-width: 599px) {
    display: none;
  }
`;

export const GradientWrapperWhite = styled.div`
  display: flex;
  cursor: pointer;
  width: auto;
  text-align: center;
  border-radius: 30px;
  border: 0px;
  box-shadow: -1px 0 0 1px #fff, -1px -1px 0 1px #ffffff,
    -1px -1px 0 1px #ffffff, 0 -1px 0 1px #ffffff, 0px 1px 0 1px #ffffff,
    1px -1px 0 1px #ffffff, 1px 1px 0 1px #ffffff, 1px 0 0 1px #ffffff;

  background: -webkit-linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
`;

export const GroupedButtonWhite = styled.button<{
  pb?: string;
  pt?: string;
  pr?: string;
  pl?: string;
}>`
  padding-top: ${props => (props.pt ? props.pt : '10px')};
  padding-right: ${props => (props.pr ? props.pr : '20px')};
  padding-bottom: ${props => (props.pb ? props.pb : '10px')};
  padding-left: ${props => (props.pl ? props.pl : '30px')};
  border: 0;
  border-bottom: 0;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  background: -webkit-linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  display: flex;
  align-items: center;
`;

export const GradientWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: auto;
  text-align: center;
  border-radius: 30px;
  border: 0px;
  box-shadow: -1px 0 0 1px #ffc9c9, -1px -1px 0 1px #ffc9c9,
    -1px -1px 0 1px #ffc9c9, 0 -1px 0 1px #f4c9ff, 0px 1px 0 1px #b5e8ff,
    1px -1px 0 1px #b5e8ff, 1px 1px 0 1px #b5e8ff, 1px 0 0 1px #b5e8ff;

  background: -webkit-linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
`;

export const GroupedButton = styled.button<{
  pb?: string;
  pt?: string;
  pr?: string;
  pl?: string;
}>`
  padding-top: ${props => (props.pt ? props.pt : '10px')};
  padding-right: ${props => (props.pr ? props.pr : '20px')};
  padding-bottom: ${props => (props.pb ? props.pb : '10px')};
  padding-left: ${props => (props.pl ? props.pl : '30px')};
  border: 0;
  border-bottom: 0;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: proxima-nova, sans-serif;
  background: -webkit-linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffc9c9;
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  text-align: center;

  @media (max-width: 599px) {
    text-align: left;
  }
`;

export const FixedBanner = styled.div`
  background: linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  text-align: center;

  width: 100%;
  a {
    display: inline-block;
    text-decoration: none;
    padding: 17px 0;
    font-family: proxima-nova, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #172643;
    span {
      text-decoration: underline;
    }
    @media (max-width: 599px) {
      font-size: 14px;
      max-width: 315px;
      line-height: 18px;
    }
    @media (min-width: 599px) and (max-width: 900px) {
      max-width: 434px;
    }
  }
`;
