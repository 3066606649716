import React from 'react';
import Modal, { Props } from 'react-modal';
import styled from '@emotion/styled';

import { gray20, secondary } from '@styles/colors';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#__next');

export const StyledAlertModal: React.FunctionComponent<Props> = props => (
  <Modal
    ariaHideApp={false}
    style={{
      content: {
        top: '30%',
        left: '50%',
        transform: 'translateX(-50%)',
        right: 'unset',
        bottom: 'unset',
        padding: '0px',
        maxWidth: '600px',
        border: 0,
        borderRadius: 0,
      },
    }}
    {...props}
  />
);

export const StyledModalWrapper = styled(Modal)({
  maxWidth: '600px',
  maxHeight: '70vh',
  overflow: 'auto',
  boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.2)',
  margin: '20vh auto 0px',
  background: 'white',
  outline: '0px',
  position: 'relative',
});

export const StyledModal: React.FunctionComponent<Props> = props => (
  <StyledModalWrapper
    ariaHideApp={false}
    style={{
      content: {},
    }}
    {...props}
  />
);

export const ModalContentWrapper = styled.div({
  // padding: '25px',
  maxHeight: '60vh',
  overflow: 'auto',
  position: 'relative',
});

export const ModalTitle = styled.div({
  backgroundColor: gray20,
  color: 'black',
  textAlign: 'center',
  fontSize: '20px',
  // padding: '15px 25px',
});

export const AlertModalBodyWrapper = styled.div({
  padding: '25px 33px',
  textAlign: 'center',
});

export const ModalActionButtons = styled.div({
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  width: '100%',
  background: '#fff',
  boxSizing: 'border-box',
  padding: '25px 40px',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '20px',

  '@media (maxWidth: 599px)': {
    padding: '25px 20px',
  },
});

export const ModalHeaderWrapper = styled.div(
  ({ whiteBg }: { whiteBg?: boolean | undefined }) => ({
    padding: '20px 40px',
    display: 'grid',
    gridTemplateColumns: '14px 1fr 14px',
    gridGap: '10px',
    position: 'sticky',
    top: '0px',
    zIndex: 1,
    alignItems: 'center',
    background: secondary,
    backgroundColor: whiteBg ? '#ffffff' : secondary,

    '@media (max-width: 599px)': {
      padding: '20px !important',
    },
  }),
);

export const ModalBodyWrapper = styled.div({
  padding: '30px 40px 10px',

  '@media (max-width: 599px)': {
    padding: '20px !important',
  },
});
