/* eslint-disable react/no-array-index-key */
import React from 'react';

import {
  BrandSocial,
  FooterContent,
  FooterItems,
  FooterLink,
  FooterList,
  FooterWrap,
  SFooterContent,
  SFooterWrap,
} from '@components/footer/footer.styled';
import { SvgIcon } from '@components/icon';
import { BaseText } from '@components/shared/common';
import SocialLinksAndIcons from '@components/social-links';
import { FooterProps } from '@models/common/footer';
import useStaticPageSegment from '@services/analytics/use-analytics';

import { footerItems } from './footer-items';
import { preOpeningFooterItems } from './pre-opening-footer-items';

const Footer: React.FunctionComponent<FooterProps> = ({
  large,
  small,
  ispreOpening,
}) => {
  const { trackStaticPageEvents } = useStaticPageSegment();

  const handleClick = (trackTitle: string | undefined) => {
    if (trackTitle && trackTitle !== 'Help Center') {
      trackStaticPageEvents(trackTitle, {}, 'track');
    }
    if (trackTitle === 'Help Center') {
      trackStaticPageEvents(trackTitle);
    }
  };

  const renderFooterItem = () => {
    const footerItemsList = ispreOpening ? preOpeningFooterItems : footerItems;

    return footerItemsList.map((item, _index) => (
      <FooterItems mOrder={item.mOrder} key={_index}>
        <a href={item.href} style={{ textDecoration: 'none' }}>
          <FooterLink onClick={() => handleClick(item.segmentTitle)}>
            {item.label}
          </FooterLink>
        </a>
      </FooterItems>
    ));
  };

  const renderSmallFooter = () => (
    <SFooterWrap>
      <SFooterContent className="s-footer">
        <SvgIcon src="bs-short.svg" alt="Brand Name" />
      </SFooterContent>
    </SFooterWrap>
  );

  const renderYogaSuanaLogo = () => (
    <>
      <BrandSocial>
        <SvgIcon src="bs-short.svg" alt="Brand Name" />
        <BaseText
          className="brandname"
          transform="uppercase"
          fWeight="700"
          css={{ letterSpacing: '2.25px' }}
        >
          infrared yoga & saunas
        </BaseText>
      </BrandSocial>
      <SocialLinksAndIcons isFromFooter />
    </>
  );

  const renderLargeFooter = () => (
    <FooterWrap>
      <FooterContent>
        <div>{renderYogaSuanaLogo()}</div>
        <div>
          <FooterList>{renderFooterItem()}</FooterList>
        </div>
      </FooterContent>
    </FooterWrap>
  );

  return (
    <>
      {large && renderLargeFooter()}
      {small && renderSmallFooter()}
    </>
  );
};

export default Footer;
