import React from 'react';
import Head from 'next/head';

import { MetaTagProps } from '@models/common/meta-tag';

const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
const FACEBOOK_DOMAIN_VERIFICATION_CONTENT =
  process.env.FACEBOOK_DOMAIN_VERIFICATION_CONTENT ||
  '01t5edxg4hrok3ndy3xtdl64ipzkrl';

const MetaTag: React.FunctionComponent<MetaTagProps> = ({
  titleSuffix,
  children,
}) => {
  const content =
    "Inside a Brightside studio, join one of our infrared yoga classes or book a private infrared sauna suite. We'll see you on the Brightside!";

  return (
    <Head>
      <title>Brightside Infrared Yoga and Sauna | {titleSuffix}</title>

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="yoga classes, yoga studio, yoga classes near me, yoga workshops, infrared sauna"
      />
      <meta name="description" content={content} />
      <meta name="image" content="%%Brand Image from Brightside%%" />

      {/* <!-- Schema.org for Google --> */}
      <meta
        itemProp="name"
        content={`Brightside Infrared Yoga and Sauna | ${titleSuffix}`}
      />
      <meta itemProp="description" content={content} />
      <meta itemProp="image" content="%%Brand Image from Brightside%%" />

      {/* <!-- Twitter --> */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={`Brightside Infrared Yoga and Sauna | ${titleSuffix}`}
      />
      <meta name="twitter:description" content={content} />
      <meta name="twitter:site" content="brightsideyoga" />
      <meta name="twitter:creator" content="@brightsideyoga" />
      <meta
        name="twitter:image:src"
        content="%%Brand Image from Brightside%% "
      />

      {/* <!-{/* - Open Graph general (Facebook, Pinterest & Google+) --> */}
      <meta
        name="og:title"
        content={`Brightside Infrared Yoga and Sauna | ${titleSuffix}`}
      />
      <meta name="og:description" content={content} />
      <meta
        name="og:image"
        content="https://www.brightsideyoga.com/images/home1preOpening.png"
      />
      <meta name="og:url" content="https://brightsideyoga.com/" />
      <meta name="og:site_name" content="Brightside Infrared Yoga and Sauna" />
      <meta name="og:locale" content="en_us" />
      {/* <meta name="fb:admins" content="%%pull from facebook app%%" /> */}
      <meta name="fb:app_id" content={FACEBOOK_APP_ID} />
      <meta name="og:type" content="website" />
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Corporation',
            name: 'Brightside Infrared Yoga and Sauna',
            alternateName: 'We’ll see you on the brightside.',
            url: 'https://brightsideyoga.com/',
            logo: '%%Brand Image from Brightside%%',
            sameAs: [
              'https://instagram.com/thebrightside',
              'https://twitter.com/brightsideyoga',
              'https://facebook.com/thebrightsideyoga',
              'https://brightsideyoga.com',
              'https://www.youtube.com/channel/brightsideyoga',
            ],
          }),
        }}
      />
      {/* Facebook auth metatag */}
      <meta
        name="facebook-domain-verification"
        content={FACEBOOK_DOMAIN_VERIFICATION_CONTENT}
      />
      {children}
    </Head>
  );
};
export default MetaTag;
