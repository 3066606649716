import React from 'react';

import { LocationService } from '@models/location';
import { useLocations } from '@services/yoga-sauna';
import { SERVICE } from '@utils/constants';

const useYogaSaunaLocation = (): {
  yogaLocation: LocationService | undefined;
  saunaLocation: LocationService | undefined;
  getSpecificLocation: (arg: string) => LocationService | undefined;
} => {
  const { data: locations = [] } = useLocations();
  const [location] = locations;

  const yogaLocation = location?.service?.find(
    item => item.service.name === SERVICE.yoga,
  );
  const saunaLocation = location?.service?.find(
    item => item.service.name === SERVICE.sauna,
  );

  const getSpecificLocation = React.useCallback(
    (type: string) => (type === SERVICE.yoga ? yogaLocation : saunaLocation),
    [yogaLocation, saunaLocation],
  );

  return React.useMemo(
    () => ({ yogaLocation, saunaLocation, getSpecificLocation }),
    [yogaLocation, saunaLocation, getSpecificLocation],
  );
};

export default useYogaSaunaLocation;
