import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import VisuallyHidden from '@reach/visually-hidden';

import { SvgIcon } from '@components/icon';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const Loader = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
});

Loader.defaultProps = {
  'aria-label': 'loading',
};

const blink = keyframes({
  '50%': { opacity: 0 },
});

const Img1 = styled.img({
  animation: `${blink} 2s ease-out infinite`,
  animationDirection: 'alternate',
});

const Img2 = styled.img({
  animation: `${blink} 2s ease-in-out infinite`,
  animationDirection: 'alternate',
});

const LoaderWrap = styled.div({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(23, 38, 67, 0.7)',
  zIndex: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const LoaderText = styled.div({
  color: '#b5e8ff',
  fontSize: 24,
  letterSpacing: 2,
  lineHeight: '38px',
  fontFamily: 'proxima-nova, sans-serif',
  fontWeight: 700,
  textTransform: 'uppercase',
  paddingTop: 10,
});

const LoaderImgWrap = styled.div({
  position: 'relative',
  width: 73,
  height: 78,

  img: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
});

interface FullLoaderProps {
  loaderText?: string;
  secondaryText?: string;
}

const FullPageLoader: React.FunctionComponent<FullLoaderProps> = ({
  loaderText,
  secondaryText,
}) => (
  <LoaderWrap>
    <VisuallyHidden>loading</VisuallyHidden>
    <LoaderImgWrap>
      <Img1 alt="Loader" src="/images/svg/outline-1.svg" />
      <Img2 alt="Loader" src="/images/svg/outline-2.svg" />
      <SvgIcon src="outline-3.svg" alt="Loader" />
    </LoaderImgWrap>

    {secondaryText ? (
      <>
        <LoaderText>{loaderText}</LoaderText>
        <LoaderText>{secondaryText}</LoaderText>
      </>
    ) : (
      <LoaderText>{loaderText}</LoaderText>
    )}
  </LoaderWrap>
);

FullPageLoader.defaultProps = {
  loaderText: 'hold tight!',
  secondaryText: '',
};

export { Loader, FullPageLoader };
