import styled from '@emotion/styled';

export const FooterWrap = styled.div`
  width: 100%;
  background: #172643;
  padding: 40px 40px 48px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    padding: 45px 58px;
  }
`;

export const BrandSocial = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  // margin-bottom: 46px;
  margin-bottom: 26.5px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
`;
export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  max-width: 1100px;
  margin: auto;
  padding-left: 22px;
  padding-right: 22px;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    grid-template-columns: 30% 1fr;
    grid-gap: 45px;
  }
`;

export const FooterList = styled.ul`
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 8%;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    column-gap: unset;
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }
`;

export const FooterLink = styled.a`
  font-size: 16px;
  font-weight: 300;
  font-family: proxima-nova, sans-serif;
  color: #b5e8ff;
  letter-spacing: 0.3px;
  line-height: 27px;
  text-decoration: none;
  text-transform: none;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  text-align: left;
  width: auto;
  cursor: pointer;
`;

export const FooterItems = styled.li<{ mOrder?: number }>`
  @media (max-width: 1024px) {
    order: ${props => props.mOrder || 1};
  }
`;

export const SFooterWrap = styled.div`
  width: 100%;
  background: #ffffff;
  // padding: 70px 0 115px;
  @media (max-width: 1024px) {
    // padding: 15px 0 65px;
  }
`;

export const SFooterContent = styled.div`
  max-width: 1100px;
  margin: auto;
  height: 120px;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
  @media (max-width: 599px) {
    margin-bottom: 40px;
    height: 70px;
  }

  @media (max-width: 1180px) {
    margin: 0px 20px;
  }
`;
