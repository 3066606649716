import React from 'react';

import { SocialLinks } from '@components/menu/menu.styled';
import Facebook from '@components/social-links/facebook';
import Instagram from '@components/social-links/instagram';
import useStaticPageSegment from '@services/analytics/use-analytics';

const SocialLinksAndIcons: React.FunctionComponent<{
  isFromFooter?: boolean;
}> = ({ isFromFooter = false }) => {
  const { trackStaticPageEvents } = useStaticPageSegment();

  const fireClickEvent = (pageTitle: string) => {
    if (isFromFooter && pageTitle) {
      trackStaticPageEvents(pageTitle, {}, 'track');
    }
  };

  return (
    <SocialLinks>
      <Instagram gradient={isFromFooter} fireClickEvent={fireClickEvent} />
      <Facebook gradient={isFromFooter} fireClickEvent={fireClickEvent} />
    </SocialLinks>
  );
};

export default SocialLinksAndIcons;
