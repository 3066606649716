import React from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

import { useAuth } from '@context/auth';
import { useSaunaState } from '@context/sauna';
import { useYogaState } from '@context/yoga';
import { useYogaSaunaState } from '@context/yoga-sauna';
import { useClassTypes, useInstructors } from '@services/yoga-sauna';
import { SERVICE } from '@utils/constants';
import { pick, pickBy } from '@utils/lodash-utils';

import { analytics, SegmentEventType } from '.';

const seoPathMap: Record<string, string> = {
  'book-a-class': 'Book by Location',
  'book-a-sauna': 'Book by Location',
  instructors: 'Book by Instructor',
  'become-a-member': 'Become a Member',
  pricing: 'Pricing',
  'gift-cards': 'Gifting',
  'my-sessions': 'My Sessions',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSessionSegment = () => {
  const router = useRouter();
  const { user } = useAuth();
  const {
    selectedLocation,
    guestEmail,
    selectedSession,
    selectedPaymentMethod,
  } = useYogaSaunaState();
  const { yogaFilters } = useYogaState();
  const { saunaFilters } = useSaunaState();
  const { data: availableInstructors = [] } = useInstructors(
    { filters: { locationId: selectedLocation?.locationId } },
    {
      enabled: !!selectedLocation,
    },
  );
  const { data: allClassTypes = [] } = useClassTypes();
  const availableClassTypes = allClassTypes.filter(
    item => item.name === SERVICE.yoga,
  );
  const [yogaClassTypes] = availableClassTypes;
  const pricingPathMap: Record<string, string> = {
    'book-a-class': 'Pricing Options',
    'book-a-sauna': 'Pricing Options',
    instructors: 'Pricing Options',
    'become-a-member': 'Become a Member',
  };

  const analyticsFields = React.useMemo(
    () => ({
      Category: (() => {
        const pathName = router.pathname.split('/')[1];
        const asPath = router.asPath.split('/')[1];
        const categoryType = seoPathMap[pathName] || seoPathMap[asPath];
        return categoryType;
      })(),
      'Logged in': user ? 'Yes' : 'No',
      Location: selectedLocation?.name,
      'Bringing Guest': guestEmail ? 'Yes' : 'No',
      'Time Window': (() =>
        yogaFilters?.startTime || saunaFilters?.startTime ? 'Yes' : 'No')(),
      'Instructor Selected': (() =>
        yogaFilters?.instructorIds?.length
          ? availableInstructors
              .filter(item =>
                yogaFilters?.instructorIds?.includes(item.id.toString()),
              )
              .map(item => `${item.firstName} ${item.lastName}`)
              .join(',')
          : 'None')(),
      'Class Type Selected': (() =>
        yogaFilters?.serviceTypeIds?.length
          ? yogaClassTypes?.types
              .filter(item =>
                yogaFilters?.serviceTypeIds?.includes(item.id.toString()),
              )
              .map(item => item.name)
              .join(',')
          : 'None')(),
      'Session Time': (() =>
        selectedSession
          ? dayjs(
              `${selectedSession?.start_date} ${selectedSession?.start_time}`,
            ).format('MM-DD-YYYY HH:mm')
          : 'None')(),
      'Booking For': guestEmail ? 'Guest' : 'Myself',
      'Pricing Option': selectedPaymentMethod?.label,
      'Referring Page Title': (() => {
        const pathName = router.pathname.split('/')[1];
        const categoryType = seoPathMap[pathName];
        return categoryType;
      })(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      availableInstructors,
      guestEmail,
      router.pathname,
      saunaFilters?.startTime,
      selectedLocation?.name,
      selectedPaymentMethod?.label,
      selectedSession,
      user,
      yogaClassTypes?.types,
      yogaFilters?.instructorIds,
      yogaFilters?.serviceTypeIds,
      yogaFilters?.startTime,
    ],
  );

  const trackSessionEvents = React.useCallback(
    (
      pageTitle: string,
      requiredFields: Array<string>,
      additionalFields: Record<string, any> = {},
      event: SegmentEventType = 'page',
    ) => {
      const fields = {
        ...pickBy(pick({ ...analyticsFields }, requiredFields)),
        ...additionalFields,
      };

      analytics(pageTitle, { ...fields }, event);
    },
    [analyticsFields],
  );

  return { trackSessionEvents, pricingPathMap };
};

export default useSessionSegment;
