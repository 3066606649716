import { FooterItem } from '@models/common/footer';

export const footerItems: FooterItem[] = [
  {
    label: 'Locations',
    href: '/locations',
    segmentTitle: 'Footer - Locations',
  },
  {
    label: 'Meet Our Instructors',
    href: '/instructors',
    segmentTitle: 'Footer - Meet Our Instructors',
  },
  {
    label: 'About Us',
    href: '/about',
    segmentTitle: 'Footer - About Us',
  },
  {
    label: 'Terms of Use',
    href: '/terms',
  },
  {
    label: 'Pricing',
    href: '/pricing',
    segmentTitle: 'Footer - Pricing',
  },
  {
    label: 'Yoga Classes',
    href: '/classes',
    segmentTitle: 'Footer - Classes',
  },
  {
    label: 'Philanthropy',
    href: '/philanthropy',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    label: 'Membership',
    href: '/become-a-member',
    segmentTitle: 'Footer - Membership',
  },
  {
    label: 'Infrared & Chromotherapy',
    href: '/what-is-infrared',
    segmentTitle: 'Footer - Infrared & Chromotherapy',
  },
  {
    label: 'Press',
    href: 'https://press.brightsideyoga.com',
    segmentTitle: 'Footer - Press',
  },
  {
    label: 'Help',
    href: 'https://help.brightsideyoga.com/hc/en-us',
    segmentTitle: 'Footer - Help',
  },
  {
    label: 'Gift Cards',
    href: '/gift-cards',
    segmentTitle: 'Footer - Give a Gift',
  },
  {
    label: 'Careers',
    href: '/careers',
    segmentTitle: 'Footer - Careers',
  },
  {
    label: 'Franchise',
    href: '/franchise',
    segmentTitle: 'Footer - Franchise',
  },
  {
    label: '(888) 851-5119',
    href: '#',
  },
];
