import React from 'react';
// import Image from 'next/image';

const SvgIcon: React.FunctionComponent<React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>> = ({ src, alt = '', ...props }) => (
  <img src={`/images/svg/${src}`} alt={alt} {...props} />
);

const PngIcon: React.FunctionComponent<React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>> = ({ src, alt = '', ...props }) => (
  <img src={`/images/${src}`} alt={alt} {...props} />
);

export { SvgIcon, PngIcon };
