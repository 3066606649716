import React from 'react';

import { SvgIcon } from '@components/icon';
import { facebookUrl } from '@utils/constants';

interface FacebookProps {
  fireClickEvent: (args: string) => void;
  gradient?: boolean;
}

const Facebook: React.FunctionComponent<FacebookProps> = ({
  fireClickEvent,
  gradient,
}) => (
  <a
    href={facebookUrl}
    target="_blank"
    rel="noreferrer"
    onClick={() => fireClickEvent('Footer - Facebook')}
  >
    {gradient ? (
      <SvgIcon width={32} src="fb-gradiant.svg" alt="Facebook" />
    ) : (
      <SvgIcon width={32} src="fb-icon.svg" alt="Facebook" />
    )}
  </a>
);

export default Facebook;
