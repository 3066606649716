import React from 'react';

import {
  ModalContentWrapper,
  ModalTitle,
  StyledModal,
} from '@components/shared/modal';
import {
  ModalContextState,
  ModalContextValue,
  ModalProps,
} from '@models/common/modal';
import { callAllEach as callAll } from '@utils/common';

import ModalHeader from './modal-header';

const ModalContext = React.createContext<ModalContextValue>(undefined);

const Modal: React.FunctionComponent = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />;
};

const ModalDismissButton = ({
  children: child,
}: {
  children: React.ReactElement<any>;
}): React.ReactElement => {
  const [, setIsOpen] = React.useContext(ModalContext) as ModalContextState;
  return React.cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  });
};

const ModalOpenButton = ({
  children: child,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [, setIsOpen] = React.useContext(ModalContext) as ModalContextState;
  return React.cloneElement(child, {
    onClick: callAll(() => setIsOpen(true), child.props.onClick),
  });
};

const ModalContents: React.FunctionComponent<ModalProps> = ({
  title,
  closeOnClickOutside,
  showCloseButton,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useContext(
    ModalContext,
  ) as ModalContextState;

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={() =>
        closeOnClickOutside ? setIsOpen(false) : undefined
      }
      {...props}
    >
      <ModalTitle data-testid="modal-title">
        <ModalHeader
          title={title ?? undefined}
          closeModal={() => setIsOpen(false)}
          showCloseButton={showCloseButton}
        />
      </ModalTitle>
      <ModalContentWrapper>{children}</ModalContentWrapper>
    </StyledModal>
  );
};

export { Modal, ModalDismissButton, ModalOpenButton, ModalContents };
