import { makeActionCreator } from '@actions/index';
import { GiftActionTypes } from '@models/gift';
import { GiftRecipientForm } from '@models/gift/gift-recipient';
import { PricingModel } from '@models/memberships/membership';

const setSelectedGiftType = makeActionCreator<
  GiftActionTypes.SET_SELECTED_GIFT_TYPE,
  PricingModel | null
>(GiftActionTypes.SET_SELECTED_GIFT_TYPE);

const setIsOneMonthGiftType = makeActionCreator<
  GiftActionTypes.SET_IS_ONE_MONTH_GIFT_TYPE,
  boolean
>(GiftActionTypes.SET_IS_ONE_MONTH_GIFT_TYPE);

const setGiftRecipientInfo = makeActionCreator<
  GiftActionTypes.SET_GIFT_RECIPIENT_INFO,
  GiftRecipientForm
>(GiftActionTypes.SET_GIFT_RECIPIENT_INFO);

const reset = makeActionCreator<GiftActionTypes.RESET, undefined>(
  GiftActionTypes.RESET,
);

export {
  setSelectedGiftType,
  setGiftRecipientInfo,
  reset,
  setIsOneMonthGiftType,
};
