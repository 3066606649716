import styled from '@emotion/styled';

export const MenuItemLink = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px;
`;

export const MenuItemText = styled.span`
  text-decoration: none;
  font-size: 24px;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  letter-spacing: 1.56px;
  line-height: 51px;
  text-align: left;
  margin-right: 18px;
  color: #b5e8ff;
  text-transform: uppercase;
  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 40px;
  }
`;

export const MenuItemIcon = styled.span`
  width: 28px;
  heifht: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItemImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 28px;
  max-height: 28px;
`;

export const MenuWrap = styled.div<{ active?: boolean }>`
  position: fixed;
  top: 72px;
  bottom: ${props => (props.active ? '100%' : '0%')};
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  opacity: ${props => (props.active ? '0' : '1')};
  background-color: #172643;
  overflow: auto;
  transition: all 200ms linear;

  @media (max-width: 599px) {
    top: 45px;
    // padding-bottom: 120px;
  }
`;

export const MenuInnerWrap = styled.div`
  max-width: 1100px;
  margin: auto;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 32px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 100px;

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
`;

export const BigMenuList = styled.div`
  display: block;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 615px;
  > div {
    width: max-content;
  }
`;

export const SecondaryMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const GradientText = styled.a`
  display: block;
  font-size: 80px;
  line-height: 100%;
  margin-bottom: 14px;
  font-family: Aktiv Grotesk Bold, proxima-nova, sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  background: -webkit-linear-gradient(
    45.67deg,
    #ffc9c9 0%,
    #f4c9ff 45%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  text-align: left;
  cursor: pointer;
  text-transform: lowercase;
  @media (max-width: 599px) {
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const GradientUserName = styled.a`
  display: block;
  font-size: 80px;
  line-height: 130%;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  background: -webkit-linear-gradient(45.67deg, #ffc9c9 0%, #f4c9ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #f7c9ef;
  width: 100%;
  text-align: left;
  cursor: pointer;
  text-transform: lowercase;
  @media (max-width: 599px) {
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const SocialLinks = styled.div`
  display: grid;
  grid-template-columns: 32px 32px;
  grid-column-gap: 16px;

  @media (max-width: 1024px) {
    margin-top: 20px;
    padding-bottom: 3rem;
  }
`;
