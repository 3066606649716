import React from 'react';
import styled from '@emotion/styled';

import { FullPageLoader } from '@components/shared/loader';
import { ButtonProps } from '@models/common/button';
import * as colors from '@styles/colors';

const buttonVariants: Record<string, any> = {
  gradient: {
    background:
      'linear-gradient(130deg, #FFC9C9 0%, #F4C9FF 52.41%, #B5E8FF 100%)',
    color: colors.primary,
    border: 0,
  },
  gradient1: {
    background: 'linear-gradient(130deg, #FFC9C9 0%, #F4C9FF 100%)',
    color: colors.primary,
    border: 0,
  },
  gradient2: {
    background: 'linear-gradient(130deg, #F4C9FF 0%, #B5E8FF 100%)',
    color: colors.primary,
    border: 0,
  },
  gradient3: {
    background: '#B5E8FF',
    color: colors.primary,
    border: 0,
  },
  primary: {
    borderColor: colors.primary,
    background: colors.primary,
    color: colors.secondary,
  },
  secondary: {
    borderColor: colors.secondary,
    background: colors.secondary,
    color: colors.primary,
  },
  outline: {
    borderColor: colors.secondary,
    background: colors.transparent,
    color: colors.primary,
    fontFamily: 'proxima-nova,sans-serif',
    fontWeight: 600,
  },
  icon: {
    borderColor: colors.transparent,
    background: colors.transparent,
    color: colors.transparent,
    padding: '0px',
    lineHeight: '100%',
    height: 'auto',
  },
  ghost: {
    borderColor: colors.gray20,
    background: colors.gray20,
    color: colors.text,
  },
  base: {
    borderColor: colors.transparent,
    background: colors.transparent,
    color: colors.text,
  },
  link: {
    borderColor: colors.transparent,
    background: colors.transparent,
    color: colors.text,
    padding: 0,
    textDecoration: 'underline',
    borderRadius: 0,
    width: 'auto',
  },
  actionLinks: {
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '2px',
    lineHeight: '20px',
    borderColor: colors.transparent,
    background: colors.transparent,
    color: colors.text,
    padding: 0,
    textDecoration: 'underline',
    borderRadius: 0,
    width: 'auto',
  },
  waitlistFull: {
    borderColor: colors.transparent,
    background: colors.transparent,
    color: colors.ashLight,
    PointerEvent: 'none',
  },
};

const buttonSizes: Record<string, any> = {
  small: {
    fontSize: '15px',
    letterSpacing: '1.1px',
    lineHeight: '18px',
    padding: '8px 20px',
    width: 'auto',
  },
};

const StyledButton = styled.button<ButtonProps>(
  {
    padding: '12px 16px',
    border: '1px solid',
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '18px',
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 700,
    letterSpacing: '1.69px',
    lineHeight: '22px',
    textAlign: 'center',
    maxWidth: '377px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
  },
  ({ disabled = false, variant = 'gradient', btnSize = '' }) => {
    if (disabled && variant === 'link') {
      return [
        buttonVariants[variant],
        {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      ];
    }
    if (disabled) {
      return {
        background: colors.gray,
        color: colors.gray40,
        borderColor: colors.gray,
        cursor: 'not-allowed',
      };
    }
    if (btnSize) {
      return [buttonSizes[btnSize], buttonVariants[variant]];
    }
    return buttonVariants[variant];
  },
);

const Button: React.FunctionComponent<ButtonProps> = ({
  loading,
  hideFullPageLoader = false,
  isTextRequired = false,
  children,
  ...rest
}) => (
  <>
    <StyledButton {...rest}>{children}</StyledButton>
    {/* eslint-disable-next-line no-nested-ternary */}
    {loading && !hideFullPageLoader ? (
      isTextRequired ? (
        <FullPageLoader
          loaderText="Just a few more seconds"
          secondaryText="& you’re all set!"
          css={{ marginLeft: 5 }}
        />
      ) : (
        <FullPageLoader css={{ marginLeft: 5 }} />
      )
    ) : null}
  </>
);

export { Button };
