import { FilterTypes } from '@models/session';
import { YogaActionTypes } from '@models/yoga';

import { makeActionCreator } from './index';

const setYogaFilters = makeActionCreator<
  YogaActionTypes.SET_YOGA_FILTERS,
  FilterTypes
>(YogaActionTypes.SET_YOGA_FILTERS);

const clearYogaFilters = makeActionCreator<
  YogaActionTypes.CLEAR_YOGA_FILTERS,
  undefined
>(YogaActionTypes.CLEAR_YOGA_FILTERS);

export { setYogaFilters, clearYogaFilters };
